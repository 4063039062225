var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"41bDtEdE7SJOHqLzE_k3c"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { SENTRY_CAPTURE_RATE, SENTRY_DSN, SENTRY_ENABLE } from './sentry.constants.mjs';

import {
  BrowserClient,
  getCurrentHub,
  defaultStackParser,
  makeFetchTransport,
  Dedupe,
  HttpContext,
  Breadcrumbs,
  LinkedErrors
} from '@sentry/nextjs';

export const sentryClient = new BrowserClient({
  enabled: SENTRY_ENABLE,
  dsn: SENTRY_DSN,

  tracesSampleRate: SENTRY_CAPTURE_RATE,
  replaysSessionSampleRate: SENTRY_CAPTURE_RATE,
  replaysOnErrorSampleRate: 1.0,
  stackParser: defaultStackParser,

  // Стандартные интеграции Sentry
  integrations: [new Dedupe(), new HttpContext(), new Breadcrumbs(), new LinkedErrors()],
  transport: makeFetchTransport,
  beforeSend: (event, hint) => {
    // Собирать оригинальные ошибки, до их изменения из-за каких-то библиотек или другой "магии"
    const exception = hint.originalException as Error;

    // Собирать только ошибки, которых есть явный Stack Trace. Анонимные ошибки собирать нет смысла
    return exception?.stack && !exception.stack.includes('<anonymous>') ? event : null;
  }
});

getCurrentHub().bindClient(sentryClient);

// Загружаем это динамически, чтобы не пакеты не попали в основной бандл. Ускоряет начальную загрузку
// eslint-disable-next-line
import('@sentry/nextjs').then(({ Replay, BrowserTracing }) => {
  sentryClient.addIntegration(new Replay({ maskAllText: false }));
  sentryClient.addIntegration(new BrowserTracing());
});
